import "./aboutUs.css";
import mySecImagee from '../../images/Group1331.png';
import missonPic from '../../images/missonPic.png';
import vissonPic from '../../images/vissonPic.png';
import missonIcon from '../../images/missonIcon.png';
import vissonIcon from '../../images/vissonIcon.png';
import KeyFeat from '../../images/KeyFeat.png';
import KeyLogo2 from '../../images/KeyLogo2.png';
import KeyLogo1 from '../../images/KeyLogo1.png';
import section4 from '../../images/section4.png';

import imgg from '../../images/man.png';
import Header from '../header/Header';
import Footer from "../footer/Footer";
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../context/LanguageContext';

function AboutUs() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [AboutData, setAboutData] = useState([]);
  // const [data, setData] = useState([]);

  useEffect(() => {
    const langId = language === "en" ? 1 : 2;
    const apiUrl = `https://na.learnovative.uk/api/v1/Front/About/${langId}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      // console.log('Fetched Data:', data.data); 
       // Debugging line to check fetched data
      setAboutData(data.data[0]);
      // console.log('Fetched Data:', data.data[0].aboutID);

    })
    .catch((error) => console.error('Error:', error));
  }, [language]);

  return (
    <>
      <Header />
     <div>
      <div className="main-about">
        <div className="container">
<div className="row">
<div className="col-12 col-md-6 col-sm-12 ">
  <div>
<img className="imgg-top" src={imgg}  alt="..." />
</div>
</div>
<div className="col-12 col-md-6 col-sm-12">
<div className="">
<h1 className="head-about">{t('About')}</h1>
<h1 className="head-about2">{t('About2')}</h1>
<p className="pargraph-about" dangerouslySetInnerHTML={{ __html: AboutData.whoWeAre }}/>

</div>
</div>

</div>
<div className="row">
<div className="col-12 col-md-6 col-sm-12 ">
  <div>
<img className="imgg-section2" src={missonPic}  alt="..." />
</div>
</div>
<div className="col-12 col-md-6 col-sm-12">
<div className="">
<h1 className="head-about"><span><img src={missonIcon}/></span> {t('Mission')} </h1>
<p className="pargraph-about" dangerouslySetInnerHTML={{ __html: AboutData.mission }}  />

</div>
</div>
</div>
<div className="row ">
<div className="col-12 col-md-6 col-sm-12">
<div className="">
<h1 className="head-about"><span><img src={vissonIcon}/></span> {t('Vission')} </h1>
<p className="pargraph-about" dangerouslySetInnerHTML={{ __html: AboutData.vission }}/>

</div>
</div>
<div className="col-12 col-md-6 col-sm-12 ">
<div>
<img className="imgg-section3" src={vissonPic}  alt="..." />
</div>
</div>
</div>
<br />

<div className="keyFeatures">
        <div className="mainJeayHead">
          <img src={KeyFeat} alt="Key Features" />
          <h2 className='KeyFeatHeadMain'>{t('Features')}</h2>
        </div>
        <div className=''>
          <div className="container ">
            <div className="row ml-1">
              <div className="col-1"></div>
              <div className="col-12 col-lg-3 ">
           <div className="keyCards11">

           <img className='mt-5' src={KeyLogo1} alt="Key Logo 1" />
                <h3 className='keyPara11'>{t('KeyOne-P1')}</h3>
                <p className='keyPara12'>{t('KeyOne-P2')}</p>
           </div>
              </div>
              <div className="col-12 col-lg-3 ">
              <div className="keyCards12 mt-2">
                <img className='mt-5' src={KeyLogo2} alt="Key Logo 2" />
                <h3 className='keyPara11'>{t('KeyTwo-P1')}</h3>
                <p className='keyPara12'>{t('KeyTwo-P2')}</p>
                </div>
              </div>
              <div className="col-12 col-lg-3 ">
                <div className="keyCards13">
                <img className='mt-5' src={KeyLogo1} alt="Key Logo 3" />
                <h3 className='keyPara11'>{t('KeyThree-P1')}</h3>
                <p className='keyPara12'>{t('KeyThree-P2')}</p>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
      
<div className="col-12 col-md-6 col-sm-12">

  <img className=" bigimg" src={mySecImagee} />
</div>
<div className="col-12 col-md-6 col-sm-12">
<div className="">
                <p className=' bigpar'>{t('Big')}</p>
              </div>
</div>


      </div>










</div>


      </div>
     </div>
      <Footer />
    </>
  );
}

export default AboutUs;
