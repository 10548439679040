/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
// import './contacts.css';
import map from "../../images/map.jpg"
import sclmedia from "../../images/sclmedia.png"
import emaill from "../../images/emailbig.png"
import Phone from "../../images/Phone.png"
import Location from "../../images/Location.png"
import Header from '../header/Header';
import Footer from "../footer/Footer";
import axios from 'axios';
import instgram from '../../images/insta.png';
import facebook from '../../images/fb.png';
import tiktok from '../../images/tiktok.png';
import youtube from '../../images/youtube.png';
import linkedin from '../../images/in.png';
import snap from '../../images/snap.png';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
function contacts() {
  const { t, i18n } = useTranslation();
  const showAlert = () => {
    Swal.fire({
      title: 'Done!',
      // text: '',
      icon: 'success',
      confirmButtonText: 'Ok'
    });
  };
      let [name, setName] = useState('');
      let [email, setEmail] = useState('');
      let [phone, setPhone] = useState('');
      let [message, setMessage] = useState('');
      let [submittedData, setSubmittedData] = useState({});
      const [status, setStatus] = useState('');
         // Handlers to update state
    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneChange = (e) => setPhone(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);

    // Handler for form submission
    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior

      // Create data object
      const data = {
          name: name,
          email: email,
          phone: phone,
          website:'www.xyz.com',
          message: message,
      };

      try {
        console.log(data)
          // Make POST request to the API
          const response = await axios.post('https://na.learnovative.uk/api/v1/Front/Contactus', {
            "name": name,
            "email": email,
            "phone": phone,
            "website": "www.xyz.com",
            "msg": message
          });
          setStatus(`Success: ${response.data.message}`); // Update status with success message
          showAlert()
          setEmail('')
          setMessage('')
          setName('')
          setPhone('')
          console.log(response)
      } catch (error) {
          setStatus(`Error: ${error.response ? error.response.data.message : 'Request failed'}`); // Update status with error message
          console.log(error)

      }
  };
  return (
    <>
    <body>
    <Header></Header>
    <div className='ThePage'>
      <div className="container">
        <div className="row ContactsHead">
          <p className='ContactsHeadOne'>{t('Contact Us')}</p>
          <p className='ContactsHeadTwo'> {t('Lets Get in Touch')}</p>


        </div>

      </div>
    </div>
    <div className="container">
      <div className="row">
      <div className="view">
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6">
            <img className='map' src={map} alt="" />
           
          </div>

          <div className="col-12 col-xl-6 col-lg-6">
            <form className="contactForm" onSubmit={handleSubmit}>
                <div className="inputContainer">
                    <div className="inputGroup mt-3">
                        <input
                            type="text"
                            className="inputFieldcontact"
                            placeholder={t('Name')}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className="inputGroup mt-3">
                        <input
                            type="email"
                            className="inputFieldcontact"
                            placeholder={t('Email')}
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="inputGroup mt-3">
                        <input
                            type="text"
                            className="inputFieldcontact"
                            placeholder={t('Phone')}
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <div className="inputGroup mt-3">
                        <textarea
                            className="inputFieldcontact"
                            placeholder={t('Message')}
                            value={message}
                            onChange={handleMessageChange}
                        />
                    </div>
                </div>
                <button className="sendbtn" type="submit">{t('Send Now')}</button>
            </form>
            {/* {status && <p>{status}</p>}  */}
        </div>

        </div>
      </div>
      </div>

    </div>
    <div className="container">
      <div className="row mt-5">

        <div className="col-12 col-md-4 col-lg-4">
          <img src={emaill} alt="" />
          <p className='emialLabel mt-3'>{t('Email')}</p>
          <p className='emailParagraph'>info@learnovative.uk</p>
        </div>
        <div className="col-12 col-md-4  col-lg-4">
          <img src={Phone} alt="" />
          <p className='emialLabel mt-3'>{t('Phone')}</p>
          <p className='emailParagraph'>01040277714</p>
        </div>
        {/* <div className="col-12 col-lg-4">
          <img src={Location} alt="" />
          <p className='emialLabel mt-3'>location</p>
          <p className='emailParagraph'>Dubai , UAE</p>
        </div> */}
      </div>
    </div>
    {/* <div className="endpart">
            <p className='finalParagraphOne'>Stay up to date</p>
            <p className='finalParagraphTwo'>get the latest on programs and events</p>
            <div>

            </div>
            </div> */}
<Footer></Footer>
</body>
      </>
  )
}

export default contacts
