import React, { useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import googleLogo from "../../images/google-logo.png";
import faceBookLogo from "../../images/facebook-logo.png";
import emailIcon from "../../images/1.png";
import passwordIcon from "../../images/66.png";
import Header from '../header/Header';
import Footer from "../footer/Footer";
import { useTranslation } from 'react-i18next';
const MySwal = withReactContent(Swal);

const Register = () => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%^&!#])[A-Za-z\d@$%^&!#]{5,}$/;
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const handleLoginClick = () => {
    window.location.href = 'http://amrsaka-001-site2.atempurl.com';

};

  const handleCheckUser = async () => {
    try {
      const data = { userName: userName };
      const response = await axios.post('https://na.learnovative.uk/api/v1/Front/UsersManagement/CheckUser/Exists', data);
      console.log(response.data);
      if (response.data.data[0].exists === 0) {
        createUser();
      } else {
        MySwal.fire({
          title: 'User already used',
          icon: 'warning',
          background: '#f8d7da',
          confirmButtonColor: '#dc3545',
        });
      }
    } catch (err) {
      console.log(err);
      MySwal.fire({
        title: 'An error occurred',
        text: 'Please try again later.',
        icon: 'error',
        background: '#f8d7da',
        confirmButtonColor: '#dc3545',
      });
    }
  };

  const createUser = async () => {
    try {
      const response = await axios.post('https://na.learnovative.uk/api/v1/Front/UsersManagement/Create', {
        userName: userName,
        userEmail: userEmail,
        password: password,
      });

      if (response.status === 200) {
        MySwal.fire({
          title: 'User created successfully!',
          icon: 'success',
          background: '#d4edda',
          confirmButtonColor: '#28a745',
        }).then(() => {
          // Redirect to the desired URL upon successful creation
          window.location.href = 'http://amrsaka-001-site2.atempurl.com';
        });
      } else {
        MySwal.fire({
          title: 'Failed to create user',
          icon: 'error',
          background: '#f8d7da',
          confirmButtonColor: '#dc3545',
        })
      }
    } catch (error) {
      console.error('Error creating user:', error);
      MySwal.fire({
        title: 'An error occurred while creating the user',
        icon: 'error',
        background: '#f8d7da',
        confirmButtonColor: '#dc3545',
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate inputs
    let hasError = false;
  
    if (!userName) {
      setUserNameError(true);
      hasError = true;
    }
    if (!userEmail) {
      setUserEmailError(true);
      hasError = true;
    }
    if (!password) {
      setPasswordError(true);
      hasError = true;
    } else if (!passwordRegex.test(password)) {
      setPasswordError(true);
      MySwal.fire({
        title: 'Invalid password',
        text: 'Password must have at least one uppercase letter, one lowercase letter, one digit, one special character (@, $, %, ^, &, *, !, #), and be at least 5 characters long.',
        icon: 'error',
        background: '#f8d7da',
        confirmButtonColor: '#dc3545',
      });
      hasError = true;
    }
  
    if (!confirmPassword) {
      setConfirmPasswordError(true);
      hasError = true;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      hasError = true;
    }
  
    if (hasError) {
      return;
    }
  
    // If all inputs are valid, proceed with user creation
    handleCheckUser();
  };

  return (
    <>
      <Header />
      <div className="myLanding">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 col-lg-6"></div> */}
            {/* <div className="col-12 col-lg-6"> */}
              <h1 className="headingreg">{t('SIGNUP')}</h1>
              <form onSubmit={handleSubmit}>
                <div className="inputContainer-A">
                  <div className={`inputGroup-reg mt-3 ${userNameError ? 'error' : ''}`}>
                    <img className="inputIconreg" src={emailIcon} alt="Email Icon" />
                    <input
                      className="inputFieldreg"
                      placeholder={t('userName')}
                      type="text"
                      id="userName"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setUserNameError(false); // Reset error state on change
                      }}
                      onBlur={() => {
                        if (!userName) {
                          setUserNameError(true);
                        }
                      }}
                      
                    />
                  </div>
                  {userNameError && <p className="errorText">{t('Username is required.')}</p>}

                  <div className={`inputGroup-reg mt-3 ${userEmailError ? 'error' : ''}`}>
                    <img className="inputIconreg" src={emailIcon} alt="Email Icon" />
                    <input
                      className="inputFieldreg"
                      placeholder={t('Email')}
                      type="email"
                      id="userEmail"
                      value={userEmail}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                        setUserEmailError(false); // Reset error state on change
                      }}
                      onBlur={() => {
                        if (!userEmail) {
                          setUserEmailError(true);
                        }
                      }}
                      
                    />
                  </div>
                  {userEmailError && <p className="errorText">{t('Email is required.')}</p>}

                  <div className={`inputGroup-reg mt-3 ${passwordError ? 'error' : ''}`}>
                    <img className="inputIconreg" src={passwordIcon} alt="Password Icon" />
                    <input
                      className="inputFieldreg"
                      placeholder={t('Password')}
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(false); // Reset error state on change
                      }}
                      onBlur={() => {
                        if (!password) {
                          setPasswordError(true);
                        }
                      }}
                      
                    />
                  </div>
                  {passwordError && <p className="errorText">{t('Password is required.')}</p>}

                  <div className={`inputGroup-reg mt-3 ${confirmPasswordError ? 'error' : ''}`}>
                    <img className="inputIconreg" src={passwordIcon} alt="Password Icon" />
                    <input
  className="inputFieldreg"
  placeholder={t(' confirm Password')}
  type="password"
  id="password"
  value={confirmPassword}
  onChange={(e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(false); // Reset error state on change
  }}
  onBlur={() => {
    if (!confirmPassword) {
      setConfirmPasswordError(true);
    } else if (!passwordRegex.test(password)) {
      setConfirmPasswordError(true);
      MySwal.fire({
        title: 'Invalid password',
        text: 'Password must have at least one uppercase letter, one lowercase letter, one digit, one special character (@, $, %, ^, &, *, !, #), and be at least 5 characters long.',
        icon: 'error',
        background: '#f8d7da',
        confirmButtonColor: '#dc3545',
      });
    }
  }}
/>
                  </div>
                  {confirmPasswordError && <p className="errorText">{t('Password dont match.')}</p>}

                </div>
                <button type="submit" className="regBtn">{t('SIGNUP')}</button>

                <p onClick={handleLoginClick} className="Forgetreg">
                  {t("Do you have an account ? Login")}
                </p>
                <p className="policyreg">
               {t('policy')}
                </p>
              </form>
            {/* </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Register;
