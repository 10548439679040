// Loader.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Loader = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 9999 }}>
    <div className="spinner-border" role="status">
      {/* <span className="sr-only">Loading...</span> */}
    </div>
  </div>
);

export default Loader;