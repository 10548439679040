import React from "react";
// import "./Login.css";
import googleLogo from "../../images/google-logo.png";
import faceBookLogo from "../../images/facebook-logo.png";
import emailIcon from "../../images/1.png";
import passwordIcon from "../../images/66.png";
import Header from '../header/Header';
import Footer from "../footer/Footer";
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t, i18n } = useTranslation();

  return (
    
    <><Header></Header><div className="myLanding">
      <div className="container">
        <div className="">
          {/* <div className="col-12 col-lg-6"></div> */}
          <div className="holdung">
            <h1 className="heading">{t('Login to your account')}</h1>
            {/* <span className="inline-text"> */}
              <p className="headingTwo">{t('Do not have an account yet?')}  <span className="headingThree">{t('Register')}</span></p>
            
            {/* </span> */}
            <div className="buttonHold-A-Login row">
              <div className="buttonOneee col-6">
                {/* <img className='logoimage' src={googleLogo} alt="" /> */}

                {/* <p className="myButonOne">
                  {" "}
                  <img className="logoimagee" src={googleLogo} alt="" />
                  Login with google{" "}
                </p> */}
              </div>
              {/* <div className="buttonOneee col-6">
         

                <p className="myButonOne">
                  {" "}
                  <img className="logoimagee" src={faceBookLogo} alt="" />
                  Login with Facebook{" "}
                </p>
              </div> */}

            </div>
            {/* <p className="OR">Or</p> */}

            <div className="inputContainer-Login-A">
              <div className="inputGroup-log mt-3">
                <img className="inputIconlog" src={emailIcon} alt="Email Icon" />
                <input type="email" className="inputFieldlog" placeholder={t('Email')} />
              </div>
              <div className="inputGroup-log mt-3">
                <img className="inputIconlog" src={passwordIcon} alt="Password Icon" />
                <input type="password" className="inputFieldlog" placeholder={t('Password')} />
              </div>
            </div>
            <p className="Forget">
              {t('Forgot your password?')}
            </p>
            <button className="LoginBtn">{t('Login')}</button>
            <p className="policylog">{t('policy')}</p>
          </div>
        </div>
      </div>

    </div><Footer></Footer></>
  );
}
