import React from 'react';
import './Instructors.css'
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ola from '../../images/NoPath.png';
import mohamed from '../../images/NoPath-1.png';
import mahmoud from '../../images/NoPath -2.png';
import { useEffect ,useState,useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from '../../context/LanguageContext';
 function Instructors () {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const [instructors, setInstructors] = useState([]);
  
    useEffect(() => {
      const langId = language === "en" ? 1 : 2;
      const apiUrl = `https://na.learnovative.uk/api/v1/Front/Instructor/All/${langId}`;
  
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setInstructors(data.data);
      })
      .catch((error) => console.error('Error:', error));
    }, [language]);
  
    // useEffect(() => {
    //   fetchData();
    // }, []);
  
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get('https://na.learnovative.uk/api/v1/Front/Instructor/All/');
    //     setData(response.data.data);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
  
    const handleNavigation = (instructorID) => {
      navigate(`/SingleInstructor/${instructorID}`);
    };
  return (
    <div >
      <div className=''>
        <div className='bg-color'></div></div>
    <Header></Header>  
    
    <div className=''>
        <div className='bg-image-slide'>
            <div className='container'>
                {/* <div className='row'> */}
                    {/* <div className='col-2'></div> */}
        <h1 className='head '>{t('Our Instructors')}</h1>
        {/* </div> */}
        </div>
        </div>
        <div className='bg-img'>
       
        <div className='container'>
         
        <div className='row mt-5'>
        {instructors.map((item) => (
          <div className='col-xl-3 col-lg-4 col-md-6'
  key={item.instructorID}
  onClick={() => handleNavigation(item.instructorID)}
  style={{ cursor: 'pointer' }}>
{/* <a href='/SingleInstructor'> */}
    <img className='w-image'  src={item.profileImgIRL}/>
    <h4 className='h-name mt-4'>{item.insName}</h4>
    <p className='p-instructor'>{item.insTitle}
</p>
{/* </a> */}
</div>
      ))}

        </div>
        </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  )
}
export default Instructors;