
import axios from 'axios';
import ResponseModal from './ResponseModal';

class getData {
_ResponseModal = new ResponseModal()
BaseURL= "https://na.learnovative.uk/api/v1/Front" ;
// BaseURL= "192.168.1.164/ELearingAPI/api/v1/Front" ;
constructor(){


}

Getdata = async (Endpoint)=>{
    try {
        const response = await axios.get(
        `${this.BaseURL}/${Endpoint}` ,
          {},
          {

          },
        );
        // console.log("response",response.data.data)
        this._ResponseModal.status= response.status
        this._ResponseModal.data= response.data.data
        this._ResponseModal.request= response.request.responseURL
        console.log("modal",this._ResponseModal)
  
  
        // console.log('items', Items)
      } catch (error) {
        console.error(error);
      }
}
}
export default getData ;