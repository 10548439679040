import React from 'react'
import Header from '../header/Header';
import Footer from "../footer/Footer";
import  './TermsAndCondition.css';
import { useTranslation } from 'react-i18next';
export default function TermsAndCondition() {
    const { t, i18n } = useTranslation();
  return (
    <div>
   <Header />
      <div className='container'>
<div>
    <h3 className='text-center'>{t('Learnovative Terms & Conditions')}</h3>
    <h5 className='text-start-terms'>*{t('Terms & Conditions')}*</h5>
    <h6 className='text-start-terms'>-{t('These terms and conditions are intended to define the framework that regulates the learning process at the Academy')}</h6>
    <h6 className='text-start-terms'>-{t('These conditions are designed to ensure an organized and productive study environment that contributes to the Achievement of learning objectives effectively')}</h6>
    <h6 className='text-start-terms'>- {t('All students and parents must commit')}</h6>
    <h6 className='text-start-terms'>{t('Those terms and conditions ensure a productive and harmonious learning Experience.')}</h6>
    <div className='linee-bottom mt-4'>
        <hr></hr>
    </div>
</div>
<div>
  
    <h5 className='text-start-terms'>* {t('Conditions of Attendance and Absence')} *</h5>
    <h6 className='text-start-terms'>- {t('The student must Prepare 10 minutes before the Lesson, sit in a quiet place, and Activate the camera throughout the lesson until the interaction is done correctly and the student has the right only to close the camera in case of a necessary Circumstance')}.</h6>
    <h6 className='text-start-terms'>-{t('The student has the right to miss only two classes during the level')} .</h6>
    <h6 className='text-start-terms'>- {t('If the student exceeds the permissible limit of Absence, he shall bear the costs of the compensatory shares according to the Academys Discretion')}.</h6>
    <div className='linee-bottom mt-4'>
        <hr></hr>
    </div>
</div>
<div>
  
    <h5 className='text-start-terms'>*{t('Conditions of Work Duties')}*</h5>
    <h6 className='text-start-terms'>- {t('The Student must complete the Assignments for the duration of the course, and if the Student fails to complete the assignments, the transition to the higher level will not take place until after completing the Assignments and Making another level test at additional costs')}
</h6>
 
    <div className='linee-bottom mt-4'>
        <hr></hr>
    </div>
</div>
<div>
  
    <h5 className='text-start-terms'>* {t('Terms of Contact')} *</h5>
    <h6 className='text-start-terms'>- {t('The parent is not entitled to speak directly with the teachers during class time, and please contact the class supervisor in case of inquiries and in case of complaints, The problem is communicated with the complaints e-mail')}.</h6>
  
    <div className='linee-bottom mt-4'>
        <hr></hr>
    </div>
</div>
<div>
  
    <h5 className='text-start-terms'>* {t('Refunds and Postponement')} *</h5>
    <h5 className='text-start-terms'>{t('Refund Policy')}</h5>

    <h6 className='text-start-terms'>- {t('The trainee has the right to claim fees after the placement test if paid in advance before the start of the course and within a maximum period of two sessions after the start of the course')}.
</h6>
<h6 className='text-start-terms'>-{t('Fees are refunded within two weeks of submitting the refund request, with the trainee bearing all transfer fees. The trainee is not entitled to claim course fees in the event of payment after the placement test and attending the first trial session')}.</h6>
    <div className='linee-bottom mt-4'>
        <hr></hr>
    </div>
</div>
<div>
  
    <h5 className='text-start-terms'>* {t('Postponement or Temporary Suspension')} *</h5>
    <h6 className='text-start-terms'>- {t('Customers have the right to temporarily suspend the subscription for one month only. If the period exceeds one month, another level determination test will be taken, with the student bearing the cost of the test')}.</h6>
<h6 className='text-start-terms'>- {t('Customers have the right to subscribe to the announced offers and add them to the balance of their levels')}.</h6>
<h6 className='text-start-terms'>- {t('The student is not entitled to a refund of fees after the temporary suspension period')}.</h6>
<h6 className='text-start-terms'>- {t('Customers must submit an official request to temporarily suspend the subscription')}.</h6>
<h6 className='text-start-terms'>{t('In the event of Returning from the Temporary suspension of participation, the student may be placed on the waiting list until the level is completed')}
</h6>
</div>

      </div>
      <Footer />
    </div>
  )
}
