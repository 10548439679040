
class ResponseModal {
    constructor(status, request,data) {

      this.status = status;
      this.request = request;
      this.data = data;
    }
  }
  
  export default ResponseModal;