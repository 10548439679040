import React, { useState } from 'react'
import Header from '../header/Header';
import Footer from "../footer/Footer";
// import './SingleCourse.css'
import Video from '../../images/Group-1375.png';
import icon from '../../images/Group-1376.png';
import icon2 from '../../images/Group-1379.png';
import course from '../../images/Group-1377.png';
import course2 from '../../images/NoPath-course.png';
import icon3 from '../../images/Group-1328.png';
import icon4 from '../../images/Group-1329.png';
import icon5 from '../../images/Group-1327.png';
import icon6 from '../../images/Group-1326.png';
import icon7 from '../../images/$.png';
import user from '../../images/NoPath2.png';
import icon1 from '../../images/Group-1328.png';
import star from '../../images/2.png';
import ola from '../../images/NoPath.png';
import instgram from '../../images/instagram-logo.png';
import { useEffect,useContext } from 'react';
import axios from 'axios';
import CardComponentAll from '../cardComponentAll/cardComponentAll';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../context/LanguageContext';



function SingleCourse() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const { courseID } = useParams();
  const condition = true; 
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState(null);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
const BuyLink = `http://amrsaka-001-site2.atempurl.com/Enroll/CourseDetails?CourseID=${courseID}`
  useEffect(() => {
    // Fetch tab data from API
    // useEffect(() => {
      const langId = language === "en" ? 1 : 2;
      const apiUrl = `https://na.learnovative.uk/api/v1/Front/Lessons/By/Course/${courseID}/${langId}`;
    
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        setTabs(data.data);
      //   if (data.data.length === 0) {
      //     // No data returned, navigate to AllCourses component
      //     navigate('/CourseCatalog');
      // }
      if (data.data.length > 0) {
        setContent({ lessonTitle: data.data[0].lessonTitle, lessonDes: data.data[0].lessonDes });
      }
      //  if (data.data.length > 0) {
      //     setContent({ lessonTitle: data.data[0].lessonTitle, lessonDes: data.data[0].lessonDes });
      //   }
        //  if (data.length > 0) {
        //   setContent({ lessonTitle: data[0].lessonTitle, lessonDes: data[0].lessonDes }); // Adjust to match your data structure
        // }
      })
      // })
      .catch((error) => console.error('Error:', error));
    }, [language, courseID,navigate]);
    
      // .catch(error => {
      //   console.error('Error fetching tab data:', error);
      // });
  // }, [courseID]);
  const handleTabClick = (index) => {
    setActiveTab(index);
    setContent({ lessonTitle: tabs[index].lessonTitle, lessonDes: tabs[index].lessonDes }); // Adjust to match your data structure
  };

  useEffect(() => {
    const langId = language === "en" ? 1 : 2;
    const apiUrl = `https://na.learnovative.uk/api/v1/Front/Course/By/${courseID}/${langId}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
       setData(data.data);
       if (data.responseArMsg === "لا يوجد بيانات") {
        // No data returned, navigate to AllCourses component
        navigate('/CourseCatalog');
    }
    })
    .catch((error) => console.error('Error:', error));
  }, [language,courseID,navigate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`http://192.168.1.164/ELearningAPI/api/v1/Front/Course/By/${courseID}/1`);
  //       setData(response.data.data);
  //       console.log(response.data.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, [courseID]);
  return (
    <div>
    <div className="">
      <div className='bg-color-instructor-color'></div></div>
      <Header></Header>
      <div className=''>
      <div className='bg-color-course'></div></div>
      {/* <div className='container mb-5'>
        <div className='row'>
          <div className='col-md-1'>
        <span className='line-headline-instructor  '>Course </span></div>
        <div className='col-md-1'>
        <span className=' line-head-instructor '> Single Course </span> </div>
        </div>
      </div> */}
      {data.map((item) => (
        <div>
        <div className='bg-color-video'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 '>
              <img className='video-style m-auto mt-3' src={item.videoURL} />
              {/* <img className='icon-width' src={icon} /> */}

            </div>
            <div className='col-md-4 '>
              <div className='bg-detail-course '>
              <h4 className='detail'> <span>{item.currencyLabel}</span> {item.costAfter}</h4>
          <div className='line-bottom mt-3'></div>
              <div className='container margin-ar'>
              <div className='row '>
                  <div className=' col-7 '>
                    <p className='plan-instructor pt-4'><span ><img className='icon3-card' src={icon5} /></span> {t('Instructor')} </p>
                  </div>
                  {/* <div className='col-3 col-md-3 col-sm-3 col-lg-3'>
                  </div> */}
                  <div className='col-5 col-md-5 col-sm-5 col-lg-5'>
                    <p className='plan-instructor pt-4'>{item.insName}

</p>
                  </div>
                  <div className='line'></div>
                  <div className='col-7 '>
                    <p className='plan-instructor'><span ><img className='icon3-card' src={icon5} /></span> {t('Duration')} </p>
                  </div>
                {/* <div className='col-3 col-md-3 col-sm-3 col-lg-3'>
                  </div> */}
                 <div className='col-5 col-md-5 col-sm-5 col-lg-5'>
                    <p className='plan-instructor'>{item.duration} </p>
                 </div> 
                  <div className='line'></div>

                  <div className='col-7 '>
                    <p className='plan-instructor'><span ><img className='icon3-card' src={icon5} /></span> {t('Lecture')} </p>
                  </div>
                  {/* <div className='col-3 col-md-3 col-sm-3 col-lg-3'>
                  </div> */}
                  <div className='col-5 col-md-5 col-sm-5 col-lg-5'>
                    <p className='plan-instructor'>{item.lessonsCount} </p>
                  </div>
                  <div className='line'></div>

<div className='col-7 '>
  <p className='plan-instructor'><span ><img className='icon3-card' src={icon5} /></span> {t('level')} </p>
</div>
{/* <div className='col-3 col-md-3 col-sm-3 col-lg-3'>
</div> */}
<div className='col-5 col-md-5 col-sm-5 col-lg-5'>
  <p className='plan-instructor'>{item.levelTitle} </p>
</div>
<div className='line'></div>

<div className='col-7 '>
  <p className='plan-instructor'><span ><img className='icon3-card' src={icon5} /></span> {t('Certificate')} </p>
</div>
{/* <div className='col-3 col-md-3 col-sm-3 col-lg-3'>
</div> */}
<div className='col-5 col-md-5 col-sm-5 col-lg-5'>
  {/* <p className='plan-instructor'>{item.withCertificate.toString()} </p> */}
  {condition ? (
          <p className='plan-instructor'>included</p>
        ) : (
          <p>No</p>
        )}
</div>
                </div>
               <div className='line'></div>
               <a href={BuyLink} > <button className='background-btn  btn-large mt-6'>{t('Buy Now')}</button></a>
              </div>
           
           
              </div>
           
            </div>
          </div>
        </div>
      </div>
   
      <div className='container'>
        <div className='row'>
<div className='col-md-8'>
  <div className='row'>
    <div className=' col-8'>
  <h1 className='headline-Course-sec2 '>{item.courseTitle}</h1>
  </div>
  <div className=' col-3'>
  <span className=' lesson-eng mt-2 bullet-list-course7 '>{item.langName}</span>
  </div>
  </div>
  <p className="topic" dangerouslySetInnerHTML={{ __html: item.fullDesc }} />

  {/* <p className='topic'>{item.shortDesc}</p> */}
  <hr className='opacityy'/>
  {/* <h1 className='headline-Course-sec2'>Description:</h1> */}
  <h1 className='headline-Course-sec2'>{t('Details')}</h1>
  <div className='row'>
        {tabs.map((tab, index) => (
          <div key={index} className='col-6 col-md-3 col-lg-3 col-sm-3 mb-2'>
            <button
              className={activeTab === index ? 'btn-color' : 'btn-color2'}
              onClick={() => handleTabClick(index)}
            >
              {tab.lessonTitle} {/* Adjust to match your data structure */}
            </button>
          </div>
        ))}
      </div>
      {tabs.length >= 0 && (
  <div className="tab-content">
    {content ? (
      <div>
        {/* <h2>{content.lessonTitle}</h2> */}
        <p className="topic" dangerouslySetInnerHTML={{ __html: content.lessonDes }} />
      </div>
    ) : (
      // <p className="topic" dangerouslySetInnerHTML={{ __html: content.lessonDes }} />

      <p className="topic">{t('No lessons available for this course')}</p>
    )}
  </div>
)}
   
  {/* <p className='topic' dangerouslySetInnerHTML={{ __html: item.fullDesc }} /> */}
    <hr className='opacityy' />
   
  {/* <div className='row'>
    
<div className='col-12 col-md-6 col-lg-6 col-sm-6'>
<div className='lesson-card-single'>
<img className='lesson-img-card' src={course} />
<h4 className='text-start lesson-card-head'>Lesson 2</h4>
</div>
</div>
<div className='col-12 col-md-6 col-lg-6 col-sm-6'>
<h4 className='text-start lesson-card-head-sec2'>Lesson 2</h4>
<p className='topic-lesson-card'>Get involved in any kind of business conversation in English in addition to learning soft skills that are required in a work day.</p>
<h4 className='text-start lesson-card-head-sec3'>Description</h4>
</div>
  </div> */}
  </div>
  <div className='col-md-4 instractor-responsive'>
<img className='ola-img' src={item.profileImgIRL}/>
<h4 className='doc-sec2'>{item.insName}</h4>
<p className='title-job-course'>{item.insTitle}</p>
{/* <p className='lesson-course bullet-list-course '><span className='margin-lesson5 bullet-list-course '>180 student</span></p> */}

  </div>

  </div>
  </div>

  {/* <div className='container'>

<h1 className='headline-Testimonials'>Student FeedBack</h1>
<div className='row'>
<div className='col-12 col-md-4 col-lg-4 col-sm-12'>
<div className='card-instructor '>
    <div className="row">
        <div className='col-1'></div>
        <div className='col-2'>
        <img className='user ' src={user}/>
        </div>
        <div className='col-2'>
        <h6 className='name-testmonials '>Alexa dahb</h6>
        <p className='desc'>ui designer</p>
        </div>
    </div>
<div>
<h5 className='quote'>"</h5>
<p className='feed'>I'm glad I decided to work with you. It's really great how easy your websites are to update and manage. <span className='quote2'>"</span></p>
</div>

<div className='row'>
    <div className='col-2 widthh'>
    <img className='star' src={star} />

    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />

    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />

    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />

    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />

    </div>

</div>

</div>
</div>
<div className='col-12 col-md-4 col-lg-4 col-sm-12'>
<div className='card-instructor '>
    <div className="row">
        <div className='col-1'></div>
        <div className='col-2'>
        <img className='user ' src={user}/>
        </div>
        <div className='col-2'>
        <h6 className='name-testmonials '>Alexa dahb</h6>
        <p className='desc'>ui designer</p>
        </div>
    </div>
<div>
<h5 className='quote'>"</h5>
<p className='feed'>I'm glad I decided to work with you. It's really great how easy your websites are to update and manage. <span className='quote2'>"</span></p>
</div>
<div className='row'>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
</div>
</div>
</div>
<div className='col-12 col-md-4 col-lg-4 col-sm-12'>
<div className='card-instructor '>
    <div className="row">
        <div className='col-1'></div>
        <div className='col-2'>
        <img className='user ' src={user}/>
        </div>
        <div className='col-2'>
        <h6 className='name-testmonials '>Alexa dahb</h6>
        <p className='desc'>ui designer</p>
        </div>
    </div>
<div>
<h5 className='quote'>"</h5>
<p className='feed'>I'm glad I decided to work with you. It's really great how easy your websites are to update and manage. <span className='quote2'>"</span></p>
</div>
<div className='row'>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
    <div className='col-2 widthh'>
    <img className='star' src={star} />
    </div>
</div>
</div>
</div>
</div>
  </div> */}
  <div className='container'>
  <h1 className='headline-instructor mt-5'>{t('Courses you might like')}</h1>
  <div className='row'>
<CardComponentAll></CardComponentAll>

  </div>
  
 
  </div>
  </div>
       ))} 
  <Footer></Footer>
    </div>
  )
}
export default SingleCourse;