/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
// import './header.css';
import Logo from '../../images/Mask Group 1.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../context/LanguageContext';

function Header() {
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRegisterClick = () => {
    navigate('/Register');
  };

  const handleLoginClick = () => {
    window.location.href = 'http://amrsaka-001-site2.atempurl.com/';
  };

  const handleLanguageSelection = (courseLanguage) => {
    sessionStorage.setItem('courseLanguage', courseLanguage);
  };

  return (
    <div>
      <header className="p-3 marginn">
        <div className="container">
          <div className="row justifyy">
            <div className='col-6 responsive-header'>
              <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none logo-header">
                <img className="logo-img arimg" src={Logo} alt="Logo" />
              </a>
              <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon">&#9776;</span>
              </button>
            </div>
            <div className='col-6 headerrr'>
              <div className=''>
                <div className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                  <ul className="nav-ar nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-start mb-md-0">
                  <li><a href="/About" className="nav-link px-2 text-color">{t('About')}</a></li>

                    <li className="nav-item dropdown">
                      <a  className="nav-link text-color dropdown-toggle">{t('Explore')}</a>
                      <ul className="dropdown-menu">
                        <li><a href="/CourseCatalog"  className="dropdown-item">All</a></li>
                        <li><a href="/CourseCatalogDynamic" className="dropdown-item" onClick={() => handleLanguageSelection(1)}>English</a></li>
                        <li><a href="/CourseCatalogDynamic" className="dropdown-item" onClick={() => handleLanguageSelection(2)}>German</a></li>
                      </ul>
                    </li>
                    <li><a href="/Instructors" className="nav-link text-color">{t('Instructors')}</a></li>
                    <li><a href="/Contacts" className="nav-link px-2 text-color">{t('Contact Us')}</a></li>
                  </ul>
                  <div>
                    {language === "en" && (
                      <button
                        className="btn-lang btn me-2 ml2"
                        onClick={() => changeLanguage("ar")}
                        style={{ fontFamily: 'Segoe UI', cursor: "pointer" }}>
                        Ar
                      </button>
                    )}
                    {language === "ar" && (
                      <button
                        className="btn-lang btn me-2 ml2"
                        onClick={() => changeLanguage("en")}
                        style={{ cursor: "pointer" }}>
                        En
                      </button>
                    )}
                  </div>
                  <div className="d-flex form">
                    <button onClick={handleRegisterClick} type="button" className="btn-Register btn me-2">{t('Register')}</button>
                    <button onClick={handleLoginClick} type="button" className="btn-Login btn me-2">{t('Login')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
