import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from './Loader'; // Import the Loader component

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [direction, setDirection] = useState('ltr');
  const [loading, setLoading] = useState(false); // State for loader

  // Function to dynamically load the appropriate CSS file
  const loadCSS = (direction) => {
    if (direction === 'rtl') {
      import('../styles/ar/layout-ar.css').then(() => {
        document.body.classList.remove('ltr');
        document.body.classList.add('rtl');
      });
    } else {
      import('../styles/en/layout-en.css').then(() => {
        document.body.classList.remove('rtl');
        document.body.classList.add('ltr');
      });
    }
  };

  useEffect(() => {
    // Set direction based on language
    const setDirectionBasedOnLanguage = (lng) => {
      const rtlLanguages = ['ar', 'he', 'fa', 'ur']; // Add more RTL languages as needed
      const newDirection = rtlLanguages.includes(lng) ? 'rtl' : 'ltr';
      setDirection(newDirection);
      loadCSS(newDirection);
    };

    setDirectionBasedOnLanguage(language);

    // Listen for language changes and update direction
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
      setDirectionBasedOnLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, language]);

  const changeLanguage = (lng) => {
    setLoading(true); // Show loader
    i18n.changeLanguage(lng).then(() => {
      setTimeout(() => {
        setLoading(false); // Hide loader after 0.5 seconds
        window.location.reload();
      }, 1000);
    });
  };

  const toggleDirection = () => {
    setDirection((prevDirection) => {
      const newDirection = prevDirection === 'ltr' ? 'rtl' : 'ltr';
      loadCSS(newDirection);
      return newDirection;
    });
  };

  return (
    <LanguageContext.Provider value={{ language, direction, changeLanguage, toggleDirection }}>
      <div dir={direction}>
        {loading && <Loader />} {/* Show loader when loading is true */}
        {children}
      </div>
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
