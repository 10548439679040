// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes,Route   } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./component/home/Home";
import Header from "./component/header/Header";
import Instructors from "./component/instructors/Instructors";
import SingleInstructor from "./component/SingleInstructors/SingleInstructors.jsx";
import SingleCourse from "./component/singlecourse/SingleCourse.jsx";
import CourseCatalog from "./component/CourseCatalog/CourseCatalog.jsx"
import CourseCatalogDynamic from "./component/CourseCatalog/CourseCatalogDynamic.jsx"
import About from "./component/about/aboutUs.jsx"
import Login from "./component/Login/Login.jsx"
import Register from "./component/Register/Register.jsx"
import Contacts from "./component/contacts/contacts.jsx"

import CardComponent from './component/cardComponent/CardComponent';
import CardComponentDynamic from './component/cardComponent/CardComponentDynamic.jsx';
import React, { useEffect, useLayoutEffect, useState } from "react";
import TermsAndCondition from './component/terms/TermsAndCondition.jsx';
// import i18n from "./i18n";
// import { useTranslation } from 'react-i18next';

function App() {
  // const { t } = useTranslation();
  // const [language, setLanguage] = useState(i18n.language);
  // const courseId = 'your-course-id'; 
  // Replace with the actual course ID

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  //   setLanguage(lng);

  //   const langId = lng === "en" ? 1 : 2;
  //   const apiUrl =`http://192.168.1.164/ELearningAPI/api/v1/Front/Instructor/All/${langId}`;

  //   fetch(apiUrl, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log('API response:', data);
  //     // Handle the API response as needed
  //   })
  //   .catch((error) => console.error('Error:', error));
  // };
  // const [Lang, setLang] = useState('en');
  // const [Loading, setLoading] = useState(true);

  return (
    <div className="App">
    <body>
         <BrowserRouter> 
   <Routes>
    <Route exact path='/' element={<Home/>}/>
    <Route exact path='/Header' element={<Header/>}/>
    <Route exact path='/CardComponent' element={<CardComponent/>}/>
    <Route exact path='/CardComponentDynamic' element={<CardComponentDynamic/>}/>
    <Route exact path='/Instructors' element={<Instructors/>}/>
    <Route exact path='/SingleInstructor/:instructorID' element={<SingleInstructor/>}/>
    <Route exact path='/CourseCatalog' element={<CourseCatalog/>}/>
    <Route exact path='/CourseCatalogDynamic' element={<CourseCatalogDynamic/>}/>
    <Route exact path='/About' element={<About/>}/>
    <Route exact path='/Login' element={<Login/>}/>
    <Route exact path='/Register' element={<Register/>}/>
    <Route exact path='/SingleCourse/:courseID' element={<SingleCourse/>}/>
    <Route exact path='/Contacts' element={<Contacts/>}/>
    <Route exact path='/TermsAndCondition' element={<TermsAndCondition/>}/>

   </Routes>
  </BrowserRouter>
  </body>
    </div>
  );
}

export default App;

